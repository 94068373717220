.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
}
.el-textarea .el-textarea__inner {
  height: 200px;
}
.listWrap .el-icon-arrow-down {
  font-size: 12px;
}
.listWrap .el-icon-arrow-down:before {
  content: "\e6df" !important;
}
.listWrap .el-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.listWrap .el-tabs .el-tabs__content {
  flex: 1;
}
.btnBox .el-button.is-disabled,
.btnBox .el-button.is-disabled:focus,
.btnBox .el-button.is-disabled:hover {
  background: #a9a9a9;
  border-color: #a9a9a9;
  color: #fff;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left > .cell {
  text-align: left;
}
.el-table th.is-right > .cell {
  text-align: right;
}
.myBatch {
  padding: 1rem 1rem;
  width: 100%;
}
.myBatch .BatchBox {
  display: flex;
  height: 70px;
  border: 1px solid #ebeef5;
  border-radius: 5px;
}
.myBatch .BatchBox .BatchBoxFirst {
  width: 260px;
  background: #f2f7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.myBatch .BatchBox .BatchBoxSecond {
  width: 100%;
}
.myBatch .BatchBox .BatchBoxSecond .BatchforBox {
  display: flex;
  flex-wrap: wrap;
}
.myBatch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.myBatch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex .BatchImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myBatch .BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex img {
  width: 16px;
  height: 16px;
}
